import React, { useEffect } from 'react'
import SEO from '../components/seo'

const Projects = () => {
    useEffect(() => {
        document.body.classList.add('project-page');
        return () => {
            document.body.classList.remove('project-page');
        }
    }, [])
    return (
        <>
            <SEO title="Projects" />
                <h2 className="page-title about-color">Projects</h2>
                <div className="project-text">
                    <p>As I have started to get more and more into making things, I wanted to start keeping track of my projects and I figured this might be a good place to do that.
                        For the past few weeks I have really only been doing some <em>around the house</em> work; mostly painting. Soon we will be putting in new flooring in the house. Pretty much all of 
                        the house.
                    </p>

                    <p>I do have a few things I will be working on in the pretty near future, primarily some storage for my wife's office/museum. Those will probably be the first items I document here.</p>
                </div>
                

            
        </>
    )
}

export default Projects
